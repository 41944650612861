import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Carousel, CarouselIndicators } from 'reactstrap'

import MyCarouselIndicators from '../my-carousel-indicators/my-carousel-indicators.component'

import { CarouselWrapperProps } from './@types/carousel-wrapper.types'

const CarouselWrapper: React.FC<CarouselWrapperProps> = ({
  slides,
  noIndicators,
  noArrows,
  autoplay,
  grow,
  isRecipes,
  onIndexChange,
  setActiveSlide
}) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const next = () => {
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
    onIndexChange?.(nextIndex)
    if (setActiveSlide) {
      window.sessionStorage.setItem('currentSlideActive', nextIndex.toString())
    }
  }

  const previous = () => {
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
    onIndexChange?.(nextIndex)
    if (setActiveSlide) {
      window.sessionStorage.setItem('currentSlideActive', nextIndex.toString())
    }
  }

  const goToIndex = newIndex => {
    setActiveIndex(newIndex)
    onIndexChange?.(newIndex)
    if (setActiveSlide) {
      window.sessionStorage.setItem('currentSlideActive', newIndex.toString())
    }
  }

  useEffect(() => {
    // nawet z autoplayem samo z siebie nie ruszy
    // trzeba odpalić i wtedy chodzi
    if (autoplay) next()
    const index = window.sessionStorage.getItem('currentSlideActive')
    if (index && setActiveSlide) {
      setActiveIndex(parseInt(index, 10))
    }
  }, [])

  return (
    <div className="w-100">
      <div className="d-flex" style={grow ? { width: '100%', height: '100%' } : null}>
        {!noArrows && (
          <div
            role="button"
            tabIndex={0}
            onKeyUp={() => null}
            onClick={previous}
            className={classNames([
              'd-none my-auto d-md-flex align-items-center justify-content-center cursor-pointer',
              isRecipes ? 'mr-n70-px' : 'mx-3'
            ])}
            style={{
              visibility: slides.length > 1 ? 'visible' : 'hidden',
              zIndex: 20,
              backgroundColor: isRecipes ? 'rgba(255,255,255,0.5)' : 'transparent',
              height: '55px',
              width: '55px',
              borderRadius: '50%'
            }}
          >
            <img src="/svgs/carousel-arrow.svg" className="rotated-180" alt="biała strzałka" />
          </div>
        )}
        <div className="w-100">
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            interval={autoplay ? 4000 : false}
          >
            <CarouselIndicators
              items={[]}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
              className="d-none position-absolute"
            />
            {slides}
          </Carousel>
        </div>
        {!noArrows && (
          <div
            role="button"
            tabIndex={0}
            onKeyUp={() => null}
            onClick={next}
            className={classNames([
              'd-none my-auto d-md-flex align-items-center justify-content-center cursor-pointer',
              isRecipes ? 'ml-n70-px' : 'mx-3'
            ])}
            style={{
              visibility: slides.length > 1 ? 'visible' : 'hidden',
              zIndex: 20,
              backgroundColor: isRecipes ? 'rgba(255,255,255,0.5)' : 'transparent',
              height: '55px',
              width: '55px',
              borderRadius: '50%'
            }}
          >
            <img src="/svgs/carousel-arrow.svg" alt="biała strzałka" />
          </div>
        )}
      </div>
      {!noIndicators && slides.length > 1 && (
        <div className={isRecipes ? 'mt-n5' : 'mt-3'}>
          <MyCarouselIndicators
            isRecipes={isRecipes}
            setActiveIndex={goToIndex}
            amount={slides.length}
            activeIndex={activeIndex}
          />
        </div>
      )}
    </div>
  )
}

export default CarouselWrapper
