import React from 'react'
import { DogItemProps } from './@types/dog-item.types'
import styles from './dog-item.module.scss'
import { translate } from '../../../common/translatable/translatable'

const DogItem: React.FC<DogItemProps> = ({ imageUrl, imageAlt, nickname, content, srcset }) => {
  const t = translate
  return (
    <div className={styles.dogItem}>
      <div>
        <img className={styles.dogItem__image} src={imageUrl} srcSet={srcset} alt={imageAlt} />
      </div>
      <div className="text-left p-3" style={{ fontSize: '14px', lineHeight: '20px' }}>
        <div className="font-weight-700">{t(nickname)}</div>
        {content.map(c => (
          <p key={c} className="font-weight-500">{c}</p>
        ))}
      </div>
    </div>
  )
}
export default DogItem
