import React from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { Col, Container, Row } from 'reactstrap'

import { translate } from '../../common/translatable/translatable'
import MyCollapse from '../static-pages/my-collapse/my-collapse.component'
import { Routes } from 'src/client/routes'

export const ShortFaq: React.FC<{
  backgroundClass?: string
}> = ({ backgroundClass = 'bg-white' }) => {
  const router = useRouter()
  const mainPagePath = router.pathname == Routes.Pages_Home

  const mainPagefaq = (
    <>
      <MyCollapse
        title="Czy Piesotto to kompletna dieta?"
        content="Tak. Piesotto to kompletna dieta, która zapewnia psu idealną liczbę kalorii każdego dnia. Specjalnie skomponowana mieszanka naturalnych suplementów, opracowana przez psich dietetyków, dzięki której psom dostarczane są wszystkie niezbędne składniki pokarmowe i nie trzeba ich dodatkowo uzupełniać."
      />
      <MyCollapse
        title="Który przepis będzie najlepszy dla psa alergika?"
        content="Piesotto ma krótki i prosty skład, mamy więc pewność co ląduje w misce naszego psa. W składzie dań Piesotto nie ma zbóż, ryżu i glutenu. Dobrym wyborem w przypadku psa alergika jest smak rybny, który jest cennym źródłem kwasów Omega 3, które charakteryzują się działaniem przeciwzapalnym, niezwykle pomocnym przy alergiach pokarmowych. Pozostałe receptury Piesotto są monobiałkowe, więc w łatwy sposób można wybrać odpowiednią recepturę jedzenia lub wyeliminować ewentualne alergeny. W naszych przepisach nie znajdziesz kurczaka, który jest jednym z najbardziej alergizujących mięs. Do wyboru masz również dania bez dodatku soczewicy i strączków."
      />
      <MyCollapse
        title="Dlaczego świeże jedzenie jest lepsze niż tradycyjna karma?"
        content="Kuchnia Piesotto to tak naprawdę manifest przeciwko komercyjnym karmom, w których pochodzenie składników i wysoki proces ich przetworzenia, chowają się pod postacią jednokształtnego “granulatu” czy konserw w puszkach. W odróżnieniu od takich karm, świeżo gotowane jedzenie daje opiekunom psów pewność, że wiedzą co ląduje w misce psa. W końcu składniki da się je dostrzec gołym okiem! Piesotto pachnie i smakuje jak prawdziwe jedzenie, bo takie właśnie jest!"
      />
      <MyCollapse
        title="Co otrzymam w pierwszej paczce?"
        content="W pierwszej paczce od Piesotto znajdziesz nie tylko świeżo gotowane jedzenie, odmierzone i spakowane na każdy dzień żywienia Twojego psa. W środku znajduje się także list dla nowego członka Ekipy Piesotto z najważniejszymi informacjami na start i poradami. Nie mogliśmy zapomnieć także o zdrowej przekąsce dla piesków (i ich opiekunów 🤭) czyli bezglutenowych ciasteczkach - Piesotkach. Możesz je podjadać wspólnie z psem bo też są jakości human grade, a w dodatku wege! Całość przyjeżdża w wygodnym opakowaniu, które jest przyjazne planecie."
      />
      <MyCollapse
        title="Jak przechowywać Piesotto?"
        content="Piesotto to świeże jedzenie dlatego zaraz po ugotowaniu poddajemy je procesowi szokowego mrożenia w szczycie świeżości. W ten sposób zachowuje świeżość na dłużej. Po otrzymaniu przesyłki od Piesotto, kilka saszetek na najbliższe dni umieść w lodówce, a pozostałe schowaj w zamrażarce. Ilość jedzenia dobrana jest indywidualnie dla każdego psa i poporcjowana, tak aby zajmowała jak najmniej miejsca w szufladach zamrażarki."
      />
    </>
  )

  const planSummaryFaq = (
    <>
      <MyCollapse
        title="Czy subskrypcję mogę wstrzymać w dowolnym momencie?"
        content="Tak. Po zalogowaniu się na swoje konto istnieje możliwość wstrzymania subskrypcji. Pamiętaj jednak, żeby zmiany dokonać na minimum 72h przed planowaną realizacją zamówienia. Po tym czasie Twoje zamówienie będzie procesowane, a wszelkie zmiany na koncie tymczasowo zablokowane."
      />
      <MyCollapse
        title="Czy po złożeniu zamówienia mogę edytować dane i datę dostawy?"
        content="Tak. Planujesz wyjazd lub masz jeszcze zapas Piesotto w zamrażarce? Spokojnie! Po zalogowaniu na swoje konto klienta możesz zmienić dane dostawy paczki. Pamiętaj jednak, żeby zmiany dokonać na minimum 4 dni przed planowaną realizacją zamówienia. Po tym czasie Twoje zamówienie będzie procesowane, a wszelkie zmiany na koncie tymczasowo zablokowane. W szczególnych sprawach zachęcamy do kontaktu z naszą obsługą klienta."
      />
      <MyCollapse
        title="Czy płatności cykliczne za subskrypcję są bezpieczne?"
        content="Płatności subskrypcyjne są w pełni bezpieczną metodą. Nigdy nie przechowujemy danych Twojej karty, a sam proces płatności przebiega przez połączenie szyfrowane i jest zawsze wsparty podwójnym uwierzytelnieniem platności poprzez 3D Secure."
      />
      <MyCollapse
        title="Co, jeśli mojemu psu nie posmakuje Piesotto?"
        content="Jeśli Twój psijaciel nie polubi naszego jedzenia, nic nie tracisz! Przekaż Piesotto potrzebującym pieskom, a my zwrócimy Ci pieniądze. Ot, cała historia. W ramach gwarancji wylizanej miseczki chętnie prześlemy Wam inne smaki Piesotto."
      />
    </>
  )

  return (
    <div className={classNames('pb-5', backgroundClass)}>
      <Container className="py-3 overflow-hidden">
        <Row>
          <Col md={{ size: 10, offset: 1 }} sm={12}>
            <h2 className="text-green text-center mb-5 mt-4">
              {translate('Często zadawane pytania')}
            </h2>
            {mainPagePath ? mainPagefaq : planSummaryFaq}
          </Col>
        </Row>
      </Container>
    </div>
  )
}
