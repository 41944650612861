import isEmpty from 'lodash/isEmpty'
import { useEffect } from 'react'
import { useStateMachine } from 'little-state-machine'

import { DogPlanState } from 'src/client/common/@types/dog-plan-state.types'
import { updatePlanStorageAction } from './update-plan-storage-action'
import { PlanStorageDogs } from './use-plan-storage.types'

export const usePlanStorage = () => {
  const {
    state: { planStorage },
    actions
  } = useStateMachine({ updatePlanStorageAction })

  const initPlanStorage = () => {
    actions.updatePlanStorageAction({
      planStorage: {
        activeDog: planStorage?.activeDog || '',
        dogs: planStorage?.dogs || [] ,
        userData: {
          email: planStorage?.userData?.email || '',
          firstName: planStorage?.userData?.firstName || '',
          marketingAgreement: planStorage?.userData?.marketingAgreement || false,
          priorities: planStorage?.userData?.priorities || []
        }
      }
    })
  }

  const updatePlanStorageUserData = data => {
    actions.updatePlanStorageAction({
      planStorage: {
        ...planStorage,
        userData: data
      }
    })
  }

  const activeDogPlan = planStorage?.dogs.find((dog) => dog.name === planStorage?.activeDog)?.plan

  const updatePlanStorageDogs = ({ activeDog, dogs }: PlanStorageDogs) => {
    actions.updatePlanStorageAction({
      planStorage: {
        ...planStorage,
        activeDog: activeDog,
        dogs: dogs
          .filter(dog => dog.name !== '')
          .map(dog => ({ name: dog.name, plan: activeDogPlan, formCompleted: false, order: dog.order }))
      }
    })
  }

  const setDogFormCompleted = (dogName: string) => {
    if (!planStorage || !planStorage.dogs) {
      return
    }

    const updatedDogs = planStorage.dogs.map(dog => {
      if (dog.name === dogName) {
        return {
          ...dog,
          formCompleted: true
        }
      }

      return dog
    })

    actions.updatePlanStorageAction({
      planStorage: {
        ...planStorage,
        dogs: updatedDogs
      }
    })

    // eslint-disable-next-line consistent-return
    return updatedDogs.filter(dog => !dog.formCompleted).length > 0
  }

  const updatePlanStorageDogPlan = (dogPlan: DogPlanState, dogName: string) => {
    if (!planStorage || !planStorage.dogs) {
      return
    }

    const updatedDogs = planStorage.dogs.map(dog => {
      if (dog.name === dogName) {
        return {
          ...dog,
          plan: {
            ...dog.plan,
            ...dogPlan
          }
        }
      }

      return dog
    })

    actions.updatePlanStorageAction({
      planStorage: {
        ...planStorage,
        dogs: updatedDogs
      }
    })
  }

  const swapActiveDog = () => {
    if (!planStorage || !planStorage.dogs) {
      return null
    }

    const remainingDogs: DogPlanState[] = planStorage.dogs.filter(dog => !dog.formCompleted)

    const [newActiveDog] = remainingDogs

    if (newActiveDog) {
      actions.updatePlanStorageAction({
        planStorage: {
          ...planStorage,
          activeDog: newActiveDog.name
        }
      })

      return newActiveDog.name
    }

    return null
  }

  useEffect(() => {
    if (isEmpty(planStorage)) {
      initPlanStorage()
    }
  }, [planStorage])

  return {
    initPlanStorage,
    planStorage,
    setDogFormCompleted,
    swapActiveDog,
    updatePlanStorageDogPlan,
    updatePlanStorageDogs,
    updatePlanStorageUserData
  }
}
