import classNames from 'classnames'
import React from 'react'
import { Col, Row } from 'reactstrap'

import styles from './how-it-works.module.scss'

type Props = {
  isActive: boolean
  img: string
  imgAlt: string
  title: string
  details: string
  className?: string
  imgClassName?: string
  afterElement?: React.ReactElement
}

export const HowItWorksStep: React.FC<Props> = ({
  title,
  details,
  isActive,
  img,
  imgAlt,
  className = '',
  imgClassName = '',
  afterElement = null
}) => {
  const addAAddTheEnd = (str: string) => `${str.replace('.svg', '')}a.svg`

  return (
    <Row className={classNames(['text-purple2', [className]])}>
      <div style={{ width: 80 }} className="position-relative pl-mobile-15-px">
        <img
          src={isActive ? addAAddTheEnd(img) : img}
          width="52"
          style={{ zIndex: 1002 }}
          className={imgClassName}
          alt={imgAlt}
        />
        {afterElement}
      </div>

      <Col className="flex-1">
        <div className="mb-2">
          <div className="font-weight-700 mb-1 font-size-mobile-18 font-size-20">{title}</div>

          <div className={classNames([styles.details, 'font-weight-500 mb-30-px mb-mobile-20-px'])}>
            {details}
          </div>
        </div>
      </Col>
    </Row>
  )
}
