import classNames from 'classnames'
import React from 'react'
import { CarouselItem } from 'reactstrap'

import { translate } from '../../../common/translatable/translatable'
import CarouselWrapper from '../carousel-wrapper/carousel-wrapper.component'

import styles from './opinions-carousel.module.scss'

const OpinionsCarousel: React.FC = () => {
  const t = translate
  const items = [
    {
      img: '/images/photos/expert-maria.png',
      imgAlt: 'ekspert maria',
      srcset: '/images/photos/expert-maria.png 1x',
      key: 1,
      name: 'Maria Milczarek',
      position: 'Dietetyk zwierzęcy',
      content:
        'Piesotto może pochwalić się wysoką jakością produktów użytych do tworzenia posiłków, co wpływa na wysoką strawność pokarmu. Jest to dobra alternatywa dla tych, którzy chcą gotować psu, ale niekoniecznie mają czas na zgłębianie tajników suplementacji.'
    },
    {
      img: '/images/photos/expert-iza.jpg',
      imgAlt: 'ekspert iza',
      srcset: '/images/photos/expert-iza_2x.jpg 2x, /images/photos/expert-iza.jpg 1x',
      key: 2,
      name: 'Izabela Kozioł',
      position: 'Instruktor szkolenia psów',
      content:
        'Dla swoich piesków i dla piesków moich kursantów wybrałam Piesotto ze względu na najwyższą jakość produktów. Dieta to podstawa w naszej pracy, dlatego z czystym sumieniem polecam ich posiłki. Dla mnie są bezkonkurencyjni!'
    },
    {
      img: '/images/photos/expert-michal.jpg',
      imgAlt: 'ekspert michał',
      srcset: '/images/photos/expert-michal_2x.jpg 2x, /images/photos/expert-michal.jpg 1x',
      key: 3,
      name: 'Michał Santorski',
      position: 'Trener i behawiorysta',
      content:
        'Jako trener i behawiorysta doskonale zdaje sobie sprawę z tego, jak ważne jest prawidłowe i zdrowe odżywianie naszych pupili, dlatego wybieram Piesotto.'
    }
  ]

  const slides = items.map(opinion => (
    <CarouselItem className="text-center" key={opinion.key}>
      <div className={classNames([styles.wrapper, 'text-purple'])}>
        <img src={opinion.img} width="150" className="rounded-circle my-3" alt={opinion.imgAlt} />
        <div className="font-weight-700 mb-0.5 font-size-18">{t(opinion.name)}</div>
        <div className="mb-2 font-size-14 text-green">{t(opinion.position)}</div>
        <div className="pb-3 px-md-5 font-size-14">{`"${t(opinion.content)}"`}</div>
      </div>
    </CarouselItem>
  ))

  return <CarouselWrapper slides={slides} />
}

export default OpinionsCarousel
