import React from 'react'
import { CarouselItem, Col, Row } from 'reactstrap'
import RecipeItem from '../recipe-item/recipe-item.component'
import { RecipeCarouselProps } from './@types/recipe-carousel.types'
import { recipes } from '../data/recipes'

const RecipeCarousel: React.FC<RecipeCarouselProps> = ({ exclude }) => {
  const recipesToDisplay = recipes.filter(recipe => {
    if (!exclude) return true
    return recipe.type !== exclude
  })

  const slides = recipesToDisplay.map(recipe => (
    <CarouselItem key={recipe.type}>
      <RecipeItem {...recipe} />
    </CarouselItem>
  ))

  const calculateMargin = (i: number) => {
    const margin = 15

    return (i % 2 ? -1 : 1) * margin
  }

  return (
    <>
      <div className="d-lg-none pl-4 pr-4">
        {/* <CarouselWrapper slides={slides} /> */}
        <div className="horizontally-scrollable">
          <div style={{ width: `${recipesToDisplay.length * 300}px` }} className="d-flex flex-row justify-content-center">
            {recipesToDisplay.map(recipe => (
              <RecipeItem key={recipe.type} {...recipe} />
            ))}
          </div>
        </div>
      </div>
      <div className="d-none d-lg-block">
        <Row>
          {recipesToDisplay.map((recipe, index) => (
            <Col
              key={recipe.type}
              className="px-0"
              style={{ marginTop: `${calculateMargin(index)}px` }}
            >
              <RecipeItem {...recipe} />
            </Col>
          ))}
        </Row>
      </div>
    </>
  )
}

export default RecipeCarousel
