import { FoodFlavorEnum } from 'piesotto-shared/dog-dtos/food-flavor.enum'

export const recipes = [
  {
    img: '/images/photos2/jedzenie/wolowina_01.jpg',
    srcset: '/images/photos2/jedzenie/wolowina_01.jpg',
    type: FoodFlavorEnum.Beef,
    icon: '/svgs/icon_wolowina.svg',
    iconAlt: 'smak soczysta wołowina',
    header: 'SOCZYSTA WOŁOWINA',
    description: '<strong>Wołowina 61%</strong>, puree z batata, marchewka, burak...',
    details: {
      healthBenefits:
        'SOCZYSTA WOŁOWINA - jak każdy nasz posiłek, przygotowujemy ją od serca, z najlepszej jakości składników human grade. Aby zachować prozdrowotne właściwości wołowiny, gotujemy ją delikatnie, w niskich temperaturach i szybko zamrażamy. Dzięki temu zachowujemy świeżość jedzonka bez konieczności stosowania konserwantów. Twój PSIjaciel z pewnością to doceni i będzie mógł radośnie, z pełnym brzuszkiem hasać po łąkach.',
      contents:
        '<strong>Wołowina 61%</strong> (mięso z wołowiny 52%, serca wołowe 26%, wątroba wołowa 12%, bulion 10%), puree z batata, marchewka, burak, dynia, soczewica, jabłko, olej rzepakowy, seler, siemię̨ lniane, dodatek mineralno-witaminowy, drożdże browarnicze suszone.',
      extras:
        'Witamina A (3a672a): 2000 j.m., Witamina D3 (3a671): 300 j.m., Witamina E: 30 mg, Witamina K: 0,2 mg, Witamina B1: 0,2 mg, Witamina B2: 0,44 mg, Witamina B6: 0,2 mg, Witamina B12: 4 μg, Biotyna: 100 μg, Niacyna: 2,3 mg, Kwas pantotenowy: 2 mg, Kwas foliowy: 0,1 mg, Chlorek choliny: 4,2 mg, Magnez: 0,16 g, Sód: 0,09 g, Żelazo (3b103): 15 mg, Mangan (3b503): 1 mg, Miedź (3b405): 1,5 mg, Cynk (3b605): 20 mg, Jodek potasu (3b201): 0,3 mg, Selenian sodu (3b801): 0,04 mg.',
      dietIngredients: {
        protein: '12,5%',
        oil: '11,2%',
        ash: '1,4%',
        fiber: '0,7%',
        moisture: '69,4%',
        energy: '160 kcal / 100g',
        calcium: '0,50%',
        phosphorus: '0,30%'
      },
      slides: [
        {
          src: '/images/photos2/jedzenie/wolowina_01.jpg',
          srcset: '/images/photos2/jedzenie/wolowina_01.jpg'
        },
        {
          src: '/images/photos2/jedzenie/wolowina_02.jpg',
          srcset: '/images/photos2/jedzenie/wolowina_02.jpg'
        }
      ]
    }
  },
  {
    img: '/images/photos2/jedzenie/indyk_01.jpg',
    srcset: '/images/photos2/jedzenie/indyk_01.jpg',
    type: FoodFlavorEnum.Turkey,
    icon: '/svgs/icon_indyk.svg',
    iconAlt: 'smak delikatny indyk',
    header: 'DELIKATNY INDYK',
    description: '<strong>Indyk 60%</strong>, puree z batata, marchewka, szpinak...',
    details: {
      healthBenefits:
        'DELIKATNY INDYK - chudziutkie mięsko ze szpinakiem i delikatnym musem z zielonego groszku to zdrowa uczta dla najbardziej wymagających PSIjaciół. Ten wyborny, pełnoporcjowy posiłek odpowiada potrzebom zarówno dorosłych psów z wrażliwym brzuszkiem jak i szczeniakom, których jedzonko powinno być wyjątkowo delikatne. Zawiera 60% świeżo przygotowanego indyka (mięso, wątroba, serce) oraz najwyższej jakości wyselekcjonowane warzywa i owoce. Nie zapomnieliśmy też o komplecie witamin, które sprawią, że Twój PSIjaciel będzie rozwijał się prawidłowo.',
      contents:
        '<strong>Indyk 60%</strong> (mięso z indyka 67%, wątroba z indyka 16%, bulion 10%, serce z indyka 7%), bataty, marchewka, szpinak, soczewica, olej rzepakowy, jabłko, seler, groszek, siemię̨ lniane, dodatek mineralno-witaminowy, drożdże browarnicze suszone.',
      extras:
        'Witamina A (3a672a): 1000 j.m., Witamina D3 (3a671): 100 j.m., Witamina E: 10 mg, Witamina K: 0,2 mg, Witamina B1: 0,2 mg, Witamina B2: 0,44 mg, Witamina B6: 0,2 mg, Witamina B12: 4 μg, Biotyna: 100 μg, Niacyna: 2,3 mg, Kwas pantotenowy: 2 mg, Kwas foliowy: 0,1 mg, Chlorek choliny: 4,2 mg, Żelazo (3b103): 15 mg, Mangan (3b503): 1 mg, Miedź (3b405): 1,5 mg, Cynk (3b605): 20 mg, Jodek potasu (3b201): 0,3 mg, Selenian sodu (3b801): 0,04 mg',
      dietIngredients: {
        protein: '17,6%',
        oil: '6,9%',
        ash: '1,6%',
        fiber: '0,6%',
        moisture: '70%',
        energy: '140 kcal / 100g',
        calcium: '0,31%',
        phosphorus: '0,28%'
      },
      slides: [
        {
          src: '/images/photos2/jedzenie/indyk_01.jpg',
          srcset: '/images/photos2/jedzenie/indyk_01.jpg'
        },
        {
          src: '/images/photos2/jedzenie/indyk_02.jpg',
          srcset: '/images/photos2/jedzenie/indyk_02.jpg'
        }
      ]
    }
  },
  {
    img: '/images/photos2/jedzenie/kaczka_01.jpg',
    srcset: '/images/photos2/jedzenie/kaczka_01.jpg',
    type: FoodFlavorEnum.Duck,
    icon: '/svgs/icon_kaczka.svg',
    iconAlt: 'smak aromatyczna kaczka',
    header: 'AROMATYCZNA KACZKA',
    description: '<strong>Kaczka 60%</strong>, dynia, puree z batata, szpinak...',
    details: {
      healthBenefits:
        'AROMATYCZNA KACZKA - PSIEpyszne i obłędnie pachnące danie, któremu nie oprze się żaden niejadek. Ta wyborna potrawa świetnie sprawdzi się również u alergików. Aromatyczna kaczka jest pełnoporcjowym posiłkiem, który odpowiada potrzebom dorosłych psów i szczeniaków. Zawiera aż 65% świeżo przygotowanej kaczki (mięso, wątroba, serce), kilka kropli oleju rzepakowego i świeżo zmielone siemię lniane. Wszystkie nasze składniki są jakości human grade, więc i Ty możesz spróbować, oczywiście jeśli Twój pupil wyrazi na to zgodę :)',
      contents:
        '<strong>Kaczka 60%</strong> (mięso z kaczki 72%, serce z kaczki 15%,  bulion 8%, wątroba z kaczki 5%), dynia, puree z batata, szpinak, olej rzepakowy, siemię lniane, drożdże browarnicze suszone, dodatek mineralno-witaminowy.',
      extras:
        'Witamina A (3a672a): 800 j.m., Witamina D3 (3a671): 80 j.m., Witamina E: 8 mg, Witamina K: 0,16 mg, Witamina B1: 0,16 mg, Witamina B2: 0,35 mg, Witamina B6: 0,16 mg, Witamina B12: 3,2 μg, Biotyna: 80 μg, Niacyna: 1,64 mg, Kwas pantotenowy: 1,6 mg, Kwas foliowy: 0,08 mg, Chlorek choliny: 3,36 mg, Żelazo (3b103): 12 mg, Mangan (3b503): 0,8 mg, Miedź (3b405): 1,2 mg, Cynk (3b605): 16 mg, Jodek potasu (3b201): 0,24 mg, Selenian sodu (3b801): 0,032 mg',
      dietIngredients: {
        protein: '10,9%',
        oil: '7,8%',
        ash: '2,0%',
        fiber: '0,3%',
        moisture: '76,8%',
        energy: '120 kcal / 100g',
        calcium: '0,29%',
        phosphorus: '0,26%'
      },
      slides: [
        {
          src: '/images/photos2/jedzenie/kaczka_01.jpg',
          srcset: '/images/photos2/jedzenie/kaczka_01.jpg'
        },
        {
          src: '/images/photos2/jedzenie/kaczka_02.jpg',
          srcset: '/images/photos2/jedzenie/kaczka_02.jpg'
        }
      ]
    }
  },
  {
    img: '/images/photos2/jedzenie/ryba_01.jpg',
    srcset: '/images/photos2/jedzenie/ryba_01.jpg',
    type: FoodFlavorEnum.Fish,
    icon: '/svgs/icon_rybka.svg',
    iconAlt: 'smak świeża rybka',
    header: 'ŚWIEŻA RYBKA',
    description: '<strong>Łosoś i czarny dorsz 74%</strong>, puree z batata, dynia, cukinia...',
    details: {
      healthBenefits:
        'ŚWIEŻA RYBKA powstała głównie z myślą o najmłodszych fanach świeżego jedzenia i wodnych atrakcji. Znika z miseczki tak szybko, jak do niej wskakuje. Dba o prawidłowy rozwój dorosłych psów i szczeniaków zarówno małych jak i dużych ras. Z naszą rybką poczujesz się jak w portowym miasteczku z widokiem na długie, zdrowe życie!',
      contents:
        '<strong>Świeże ryby 74%</strong> (88% dorsz czarny, 7% łosoś, bulion rybny 5%), puree z batata, dynia, szpinak, pasternak, cukinia, olej z łososia, olej rzepakowy, dodatek mineralno-witaminowy, siemię lniane, drożdże browarnicze suszone.',
      extras:
        'Witamina A (3a672a): 1200 j.m., Witamina D3 (3a671): 120 j.m., Witamina E: 12 mg, Witamina K: 0,24 mg, Witamina B1: 0,24 mg, Witamina B2: 0,52 mg, Witamina B6: 0,24 mg, Witamina B12: 4,8 μg, Biotyna: 120 μg, Niacyna: 2,76 mg, Kwas pantotenowy: 2,4 mg, Kwas foliowy: 0,12 mg, Chlorek choliny: 5,04 mg, Żelazo (3b103): 18 mg, Mangan (3b503): 1,2 mg, Miedź (3b405): 1,8 mg, Cynk (3b605): 24 mg, Jodek potasu (3b201): 0,36 mg, Selenian sodu (3b801): 0,048 mg',
      dietIngredients: {
        protein: '12,8%',
        oil: '5,7%',
        ash: '2,1%',
        fiber: '0,6%',
        moisture: '73,7%',
        energy: '120 kcal / 100g',
        calcium: '0,38%',
        phosphorus: '0,36%'
      },
      slides: [
        {
          src: '/images/photos2/jedzenie/ryba_01.jpg',
          srcset: '/images/photos2/jedzenie/ryba_01.jpg'
        },
        {
          src: '/images/photos2/jedzenie/ryba_02.jpg',
          srcset: '/images/photos2/jedzenie/ryba_02.jpg'
        }
      ]
    }
  },
  {
    img: '/images/photos2/jedzenie/jagniecina_01.jpg',
    srcset: '/images/photos2/jedzenie/jagniecina_01.jpg',
    type: FoodFlavorEnum.Lamb,
    icon: '/svgs/icon_jagnie.svg',
    iconAlt: 'smak szlachetna jagniecina',
    header: 'SZLACHETNA JAGNIĘCINA',
    description: '<strong>Jagnięcina 60%</strong>, puree z batata, dynia...',
    details: {
      healthBenefits:
        'SZLACHETNA JAGNIĘCINA to nasz ukłon w stronę najbardziej wymagających i delikatnych brzuszków. Ta wyborna potrawa sprawdzi się także dla psów alergików i o wrażliwym przewodzie pokarmowym. Jej szlachetność wynika głównie z wykorzystania najlepszej jakości mięsa jagnięcego i delikatnych warzyw - batata, buraka, dyni czy selera. Nie zapomnieliśmy też o dodaniu witamin i minerałów, aby Twój psijaciel mógł rozwijać się prawidłowo. Gdy piesek zajada szlachetną jagnięcinę ma baaardzo zadowoloną minę!',
      contents:
        '<strong>Mięso i produkty pochodzenia zwierzęcego 60%</strong> (świeża baranina 25%, świeża jagnięcina 25%, serca jagnięce 30%, wątroba jagnięca 12%, bulion 8%), puree z batata, dynia, szpinak, seler, burak, olej rzepakowy, dodatek mineralno-witaminowy, siemię lniane, drożdże browarnicze suszone.',
      extras:
        'Witamina A (3a672a): 1500 j.m., Witamina D3 (3a671): 150 j.m., Witamina E: 15 mg, Witamina K: 0,3 mg, Witamina B1: 0,3 mg, Witamina B2: 0,66 mg, Witamina B6: 0,3 mg, Witamina B12: 6μg, Biotyna: 150 μg, Niacyna: 3,45 mg, Kwas pantotenowy: 3 mg, Kwas foliowy: 0,15 mg, Chlorek choliny: 6,3 mg, Żelazo (3b103): 22,5 mg, Mangan (3b503): 1,5 mg, Miedź (3b405): 2,25 mg, Cynk (3b605): 30 mg, Jodek potasu (3b201): 0,45 mg, Selenian sodu (3b801): 0,06 mg',
      dietIngredients: {
        protein: '10,8%',
        oil: '10,7%',
        ash: '2,0%',
        fiber: '0,3%',
        moisture: '73,4%',
        energy: '145 kcal / 100g',
        calcium: '0,38%',
        phosphorus: '0,34%'
      },
      slides: [
        {
          src: '/images/photos2/jedzenie/jagniecina_01.jpg',
          srcset: '/images/photos2/jedzenie/jagniecina_01.jpg'
        },
        {
          src: '/images/photos2/jedzenie/jagniecina_02.jpg',
          srcset: '/images/photos2/jedzenie/jagniecina_02.jpg'
        }
      ]
    }
  }
]
