import classNames from 'classnames'
import { Parser } from 'html-to-react'
import Link from 'next/link'
import { FoodFlavorEnum } from 'piesotto-shared/dog-dtos/food-flavor.enum'
import React from 'react'
import { translate } from 'src/client/common/translatable/translatable'
import { Routes } from 'src/client/routes'

import { RecipeItemProps } from './@types/recipe-item.props'
import styles from './recipe-item.module.scss'

const RecipeItem: React.FC<RecipeItemProps> = ({
  img,
  header,
  icon,
  iconAlt,
  description,
  type,
  srcset
}) => {
  const { parse } = new Parser()

  const getRecipeLink = () => {
    switch (type) {
      case FoodFlavorEnum.Beef:
        return Routes.Pages_Recipes_Beef

      case FoodFlavorEnum.Fish:
        return Routes.Pages_Recipes_Fish

      case FoodFlavorEnum.Turkey:
        return Routes.Pages_Recipes_Turkey

      case FoodFlavorEnum.Duck:
        return Routes.Pages_Recipes_Duck

      case FoodFlavorEnum.Lamb:
        return Routes.Pages_Recipes_Lamb

      default:
        return Routes.Pages_Recipes
    }
  }

  return (
    <>
      <a href={getRecipeLink()} hidden>
        {translate('Zobacz pełny skład')}
      </a>
      <Link href={getRecipeLink()}>
        <div className={classNames(styles.recipeItem, 'shadow')}>
          <div>
            <img className={styles.recipeItem__image} src={img} srcSet={srcset} alt={header} />
          </div>
          <div className={styles.recipeItem__iconWrapper}>
            <img src={icon} className={styles.recipeItem__icon} alt={iconAlt} />
          </div>
          <div className={classNames([styles.recipeItem__header, 'font-mindset'])}>{header}</div>
          <div className={styles.recipeItem__description}>{parse(description)}</div>
          <div
            style={{ flex: 1, paddingTop: '5px' }}
            className="d-flex align-items-end justify-content-center pb-4"
          >
            <span className={classNames([styles.recipeItem__link, 'text-green font-weight-700'])}>
              {translate('Zobacz pełny skład')}
            </span>
          </div>
        </div>
      </Link>
    </>
  )
}
export default RecipeItem
