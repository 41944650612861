import Cookies from 'js-cookie'
import isEmpty from 'lodash/isEmpty'
import { FbPixelEventName } from 'piesotto-shared/fbpixel/fpixel.constants'
import * as uuid from 'uuid'

export const updateFbPixelAction = (globalState, payload) => ({
  ...globalState,
  fbPixelEventId: { ...globalState.fbPixelEventId, ...payload }
})

export const initFbPixelAction = (globalState) => {
  const fbPixelEventId = { ...globalState.fbPixelEventId }

  if (isEmpty(fbPixelEventId)) {
    Object.keys(FbPixelEventName).forEach((name) => {
      fbPixelEventId[name] = uuid.v4()
    })
  }

  Cookies.set('fbPixelEventId', fbPixelEventId, { path: '/' })

  return {
    ...globalState,
    fbPixelEventId
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const clearFbPixelAction = (globalState, payload) => {
  const newGlobalState = { ...globalState }
  delete newGlobalState.fbPixelEventId

  return {
    ...newGlobalState
  }
}
