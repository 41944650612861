import { useStateMachine } from 'little-state-machine'
import { useEffect, useMemo } from 'react'

import { clearFbPixelAction, initFbPixelAction, updateFbPixelAction } from './actions'

export const useFbPixel = () => {
  const { state, actions } = useStateMachine({
    clearFbPixelAction,
    initFbPixelAction,
    updateFbPixelAction
  })

  const updateFbPixel = (fbPixelEventId) => {
    actions.updateFbPixelAction(fbPixelEventId)
  }

  const clearFbPixel = () => {
    actions.clearFbPixelAction({})
  }

  const initFbPixel = () => {
    actions.initFbPixelAction(undefined)
  }

  const fbPixelEventId = useMemo(() => state.fbPixelEventId, [state])

  const getEventIDByName = (name) => fbPixelEventId[name]

  useEffect(() => {
    initFbPixel()
  }, [])

  return {
    clearFbPixel,
    fbPixelEventId,
    getEventIDByName,
    initFbPixel,
    updateFbPixel
  }
}
