import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'

import { translate } from '../../common/translatable/translatable'
import { usePlanModal } from '../../hooks/usePlanModal'

import { HowItWorksStep } from './how-it-works-step'
import styles from './how-it-works.module.scss'

type Props = {
  isLoggedIn: boolean
}

const getMarkTopPosition = (markRef: React.MutableRefObject<HTMLDivElement>) => {
  const rect = markRef.current.getBoundingClientRect()
  const bodyRect = document.body.getBoundingClientRect()

  return rect.bottom - bodyRect.bottom
}

export const HowItWorksSection: React.FC<Props> = ({ isLoggedIn }) => {
  const t = translate

  const [scroll, setScroll] = useState(0)
  const [animationState, setAnimationState] = useState(0)

  const { toggleIsPlanModalOpen } = usePlanModal()

  const mark1Ref = useRef<HTMLDivElement>()
  const mark2Ref = useRef<HTMLDivElement>()
  const mark3Ref = useRef<HTMLDivElement>()
  const coverTopRef = useRef<HTMLDivElement>()

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY)
    })
  }, [])

  useEffect(() => {
    if (!mark1Ref.current || !mark2Ref.current || !mark3Ref.current) {
      return
    }

    const mark1Position = getMarkTopPosition(mark1Ref) + 300
    const mark2Position = getMarkTopPosition(mark2Ref) + 300
    const mark3Position = getMarkTopPosition(mark3Ref) + 300

    if (scroll < mark1Position) {
      setAnimationState(0)
    } else if (scroll > mark1Position && scroll < mark2Position) {
      setAnimationState(1)
    } else if (scroll > mark2Position && scroll < mark3Position) {
      setAnimationState(2)
    }
  }, [scroll])

  return (
    <section style={{ overflow: 'visible', background: 'transparent' }}>
      <div className={styles.stickyArea}>
        <div className="w-100 mt-n80-px">
          <div style={{ height: '75px', width: '100%', overflow: 'hidden' }}>
            <svg
              viewBox="0 0 500 150"
              preserveAspectRatio="none"
              style={{ height: '100%', width: '100%' }}
            >
              <path
                d="M-7.05,71.53 C211.34,160.36 298.81,-0.48 576.46,92.27 L500.00,150.00 L0.00,150.00 Z"
                style={{ stroke: 'none', fill: '#eaf2e6' }}
              />
            </svg>
          </div>

          <div className="bg-lightgreen">
            <Container className="pb-25-px pb-mobile-30-px">
              <Row>
                <Col xs="12" className="d-md-none">
                  <h2 className="text-green text-center my-5">{t('Jak to działa')}</h2>
                </Col>

                <Col xs="12" md="6">
                  <div className={styles.howItWorks}>
                    <img
                      alt="szczęśliwy pies"
                      className={classNames({
                        [styles.howItWorks__dog]: true
                      })}
                      src="/images/photos/hiw-5.jpg"
                      srcSet="/images/photos/hiw-5_x2.jpg 2x, /images/photos/hiw-5.jpg 1x"
                    />

                    <img
                      alt="porcje karmy piesotto"
                      className={classNames({
                        [styles.howItWorks__dog]: true,
                        'opacity-0': animationState < 2
                      })}
                      src="/images/photos/hiw-6.jpg"
                      srcSet="/images/photos/hiw-6_x2.jpg 2x, /images/photos/hiw-6.jpg 1x"
                    />
                  </div>
                </Col>

                <img
                  src="/svgs/dostawa.svg"
                  className={styles.deliveryBadge}
                  alt="dostawa w całej Polsce"
                />

                <Col xs="12" md="6">
                  <h2 className="text-green ml-80-px ml-mobile-0-px text-left mt-5 mb-40-px d-none d-md-block">
                    {t('Jak to działa')}
                  </h2>

                  <Row className={styles.textPurple}>
                    <Col>
                      <div ref={coverTopRef} className="float-left position-relative" />

                      <HowItWorksStep
                        isActive
                        img="/svgs/jtd_1.svg"
                        imgAlt="krok pierwszy jasny"
                        imgClassName={classNames(styles.dot, styles.dot1)}
                        title={t('Stwórz swój plan')}
                        details={t(
                          'Opowiedz nam o swoim psie, a my stworzymy plan zawierający spersonalizowane porcje, które zaspokoją jego unikalne potrzeby żywieniowe i kaloryczne każdego dnia.'
                        )}
                      />

                      <div ref={mark1Ref} className="float-left position-relative" />

                      <HowItWorksStep
                        isActive={animationState > 1}
                        img="/svgs/jtd_2.svg"
                        imgAlt="krok drugi jasny"
                        imgClassName={classNames(styles.dot, styles.dot2, '')}
                        title={t('Twoja pierwsza paczka')}
                        details={t(
                          'Na początek otrzymasz pierwsze pudełko ze świeżo przygotowanym jedzeniem na 2 tygodnie – to idealny czas by zobaczyć jak sprawdza się u Was Piesotto.'
                        )}
                      />

                      <div ref={mark2Ref} className="float-left position-relative" />

                      <HowItWorksStep
                        isActive={animationState > 2}
                        img="/svgs/jtd_3.svg"
                        imgAlt="krok trzeci jasny"
                        imgClassName={classNames(styles.dot, styles.dot3)}
                        title={t('Zakochaj się')}
                        details={t(
                          'Jeśli Ty i Twój pies pokochacie smak i wygodę Piesotto – sądzimy, że tak będzie! –  cyklicznie uzupełnimy lodówkę,  kiedy Wasze zapasy będą się kończyć.'
                        )}
                      />

                      <div ref={mark3Ref} className="float-left position-relative" />
                    </Col>
                  </Row>

                  <Row className="mt-3 mb-80-px  mb-mobile-20-px">
                    <Col className="text-center text-lg-left">
                      {!isLoggedIn && (
                        <>
                          <Button
                            color="primary"
                            className="rounded-pill btn-wide ml-80-px ml-mobile-0-px my-3"
                            onClick={toggleIsPlanModalOpen}
                          >
                            <span className={styles.ctaText}>{t('Stwórz plan')}</span>
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </section>
  )
}
