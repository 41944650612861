import classNames from 'classnames'
import React from 'react'
import { CarouselIndicatorProps } from './@types/my-carousel-indicators.types'
import styles from './my-carousel-indicators.module.scss'

const MyCarouselIndicators: React.FC<CarouselIndicatorProps> = ({
  setActiveIndex,
  activeIndex,
  amount,
  isRecipes
}) => {
  const createElements = () => {
    const elements = []

    for (let i = 0; i < amount; i++) {
      elements.push(
        <div
          key={i}
          onClick={() => setActiveIndex(i)}
          onKeyDown={() => null}
          className={classNames([
            isRecipes ? styles.indicators__itemRecipe : styles.indicators__item,
            activeIndex === i ? (isRecipes ? styles.activeRecipe : styles.active) : ''
          ])}
        />
      )
    }

    return elements
  }

  if (amount === 1) {
    return null
  }

  return <div className={styles.indicators}>{createElements()}</div>
}

export default MyCarouselIndicators
