import React from 'react'
import { CarouselItem, Col, Row } from 'reactstrap'
import CarouselWrapper from '../carousel-wrapper/carousel-wrapper.component'
import { DogItemProps } from '../dog-item/@types/dog-item.types'
import DogItem from '../dog-item/dog-item.component'

const DogsCarousel: React.FC<{ dogs: DogItemProps[] }> = ({ dogs }) => {
  const slides = dogs.map(dog => (
    <CarouselItem key={dog.nickname}>
      <div style={{ height: '760px' }}>
        <DogItem {...dog} />
      </div>
    </CarouselItem>
  ))

  return (
    <div>
      <div className="d-lg-none">
        {/* <CarouselWrapper slides={slides} /> */}
        <div className="horizontally-scrollable ">
          <div style={{ width: `${dogs.length * 280}px` }} className="d-flex flex-row justify-content-around">
            {dogs.map(dog => (
              <DogItem key={dog.nickname} {...dog} />
            ))}
          </div>
        </div>
      </div>
      <div className="d-none d-lg-block">
        <Row>
          {dogs.map(dog => (
            <Col key={dog.nickname} className="p-0">
              <DogItem {...dog} />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  )
}

export default DogsCarousel
