import classNames from 'classnames'
import Cookies from 'js-cookie'
import { isEmpty } from 'lodash'
import { NextPage, NextPageContext } from 'next'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import { useDiscountFromUrl } from 'src/client/hooks/useDiscountFromUrl'

import { getAuthProps } from '../common/auth-props/get-auth-props'
import { getCookies } from '../common/cookies/get-cookies'
import { CurvyTopWhite } from '../common/curvy-top/curvy-top-white'
import { handleCookieDiscount } from '../common/handle-cookie-discount/handle-cookie-discount'
import { PosterNamesEnum } from '../common/poster/poster-names.enum'
import { StaticPageProps } from '../common/static-page-server-side-props/@types/static-page-props'
import { translate } from '../common/translatable/translatable'
import { useFbPixel } from '../hooks/useFbPixel'
import { useGaEvent } from '../hooks/useGaEvent'
import { useIsDiscountBar } from '../hooks/useIsDiscountBar'
import { usePlanModal } from '../hooks/usePlanModal'
import { usePlanStorage } from '../hooks/usePlanStorage'
import { useProposal } from '../hooks/useProposal'
import { HowItWorksSection } from '../modules/home/how-it-works-section'
import { CookieStorage } from '../modules/plan/plan-storage/@enum/cookie-storage.enum'
import { HappyStories } from '../modules/shared/happy-stories'
import { ShortFaq } from '../modules/shared/short-faq'
import BrandsCarousel from '../modules/static-pages/brands-carousel/brands-carousel.component'
import CookieBar from '../modules/static-pages/cookie-bar/cookie-bar.component'
import Footer from '../modules/static-pages/footer/footer.component'
import MyNavbar from '../modules/static-pages/my-navbar/my-navbar-component'
import OpinionsCarousel from '../modules/static-pages/opinions-carousel/opinions-carousel.component'
import RecipeCarousel from '../modules/static-pages/recipes/recipe-carousel/recipe-carousel.component'
import SliderCalculator from '../modules/static-pages/slider-calculator/slider-calculator.component'
import { createLink, Routes } from '../routes'

import styles from './home.module.scss'

export const getServerSideProps = async (context: NextPageContext) => {
  const cookies = getCookies(context)
  const authProps = await getAuthProps(context)
  const discountData: any = await handleCookieDiscount(context)

  return {
    props: {
      ...cookies,
      discountData,
      ...authProps,
      settingPasswordToken: context.query['ustaw-haslo'] || null
    }
  }
}

const Home: NextPage<StaticPageProps & { settingPasswordToken: string }> = ({
  isLoggedIn,
  allowCookie,
  discountData,
  settingPasswordToken
}) => {
  const t = translate
  const { query, push, pathname } = useRouter()

  const { initGaEvent } = useGaEvent()
  const { initPlanStorage } = usePlanStorage()
  const { initProposal } = useProposal()
  const { toggleIsPlanModalOpen, initIsPlanModalOpen } = usePlanModal()
  const { isDiscountBarTrue, setIsDiscountBarTrue } = useIsDiscountBar()
  const { setDiscountFromUrl, discountFromUrl } = useDiscountFromUrl()
  const { initFbPixel } = useFbPixel()

  useEffect(() => {
    if (!discountFromUrl && !isEmpty(query)) {
      setDiscountFromUrl(query)
    }

    if (discountFromUrl) {
      push({
        pathname,
        query: discountFromUrl
      })
    }
  }, [])

  useEffect(() => {
    initFbPixel()
    initProposal()
    initPlanStorage()
    initGaEvent()
    initIsPlanModalOpen()
    setIsDiscountBarTrue()
    Cookies.remove(CookieStorage.PLAN_STORAGE_KEY, { path: '/' })
  }, [discountFromUrl])

  return (
    <>
      <Head>
        <link rel="canonical" href="https://piesotto.pl/" />
        <title>{translate('Naturalne, gotowane jedzenie dla psów - Piesotto')}</title>
        <meta
          name="description"
          content="Zdrowe, smaczne i personalizowane posiłki, przygotowywane ze świeżych składników, w niskich temperaturach i pod okiem ekspertów. Sprawdź ofertę Piesotto!"
        />
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'BreadcrumbList',
              itemListElement: [
                {
                  '@type': 'ListItem',
                  position: 1,
                  name: 'Strona główna'
                }
              ]
            })
          }}
        />
      </Head>

      <div className="position-relative">
        <div className="bg-snack">
          <MyNavbar
            hasCallToAction
            discountData={discountData}
            isLoggedIn={isLoggedIn}
            settingPasswordToken={settingPasswordToken}
          />

          <Container className="mb-n110-px">
            <div
              className={classNames('header-section-padding', {
                'header-section-padding-withDiscount': isDiscountBarTrue
              })}
            />

            <Row className={`${styles.bannerWrapper} mb-5 `}>
              <Col xs={12} lg={6} className="mt-mobile-5-px mb-mobile-70-px mb-105-px">
                <div className={styles.bestFood}>
                  <div className={styles.bestFood__header}>
                    <span>
                      {t('Najle')}
                      <span className="text-primary">{t('psie')}</span>
                    </span>
                    <br />
                    <span className="ml-100-px ml-mobile-30-px">{t('jedzenie')}</span>

                  </div>

                  <div className="font-weight-bold text-center text-lg-left pr-lg-5 py-md-1 mt-n3 font-size-22 font-size-mobile-18 mb-10-px">
                    <div className="text-purple">
                      <h1 className="font-size-22 font-size-mobile-18 font-weight-bold">
                        {t('Gotujemy, personalizujemy')}

                        <br />

                        {t(' i dostarczamy prosto do miseczki')}
                      </h1>
                    </div>
                  </div>
                </div>

                <div className="text-center text-lg-left">
                  {isLoggedIn && (
                    <Link href={createLink(Routes.MyAccount_MyPlan)}>
                      <Button color="primary" className="rounded-pill btn-wide">
                        <span className="ctaText">{t('Moje konto')}</span>
                      </Button>
                    </Link>
                  )}

                  {!isLoggedIn && (
                    <>
                      <Button
                        color="primary"
                        className="rounded-pill btn-wide mt-4"
                        onClick={toggleIsPlanModalOpen}
                      >
                        <span className="ctaText">{t('Stwórz plan')}</span>
                      </Button>
                    </>
                  )}
                </div>
              </Col>

              <Col xs={{ size: 12, order: 1 }} lg={{ size: 6, order: 2 }}>
                <div className={styles.doggos}>
                  <img
                    className={styles.dog}
                    src="/images/photos/piesotto_slider_03.jpg"
                    srcSet="/images/photos/piesotto_slider_03_2x.jpg 2x, /images/photos/piesotto_slider_03.jpg 1x"
                    alt="dieta piesotto"
                  />

                  <img
                    className={styles.dog}
                    src="/images/photos/piesotto_slider_02.jpg"
                    srcSet="/images/photos/piesotto_slider_02_2x.jpg 2x, /images/photos/piesotto_slider_02.jpg 1x"
                    alt="psia dieta piesotto"
                  />

                  <img
                    className={styles.dog}
                    src="/images/photos/piesotto_slider_04.jpg"
                    srcSet="/images/photos/piesotto_slider_04_2x.jpg 2x, /images/photos/piesotto_slider_04.jpg 1x"
                    alt="jedzenie dla psa piesotto"
                  />
                </div>
              </Col>
            </Row>
          </Container>

          <CurvyTopWhite />
        </div>

        <Container className="mt-30-px mt-mobile-0-px bg-white overflow-hidden">
          <Row className="mt-40-px mb-4">
            <Col>
              <div className="text-center">
                <h2 className="text-green h2 my-3">{t('Piszą o nas')}</h2>

                <BrandsCarousel />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs="12" md={{ size: 10, offset: 1 }}>
              <div className="text-center mt-75-px mt-mobile-20-px ">
                <h2 className="text-green h2">
                  {t('Świeże składniki jakości Human Grade')}
                </h2>

                <div
                  className={classNames([
                    styles.bowlSubtitle,
                    'text-purple font-size-16 font-size-mobile-16 my-3 pr-mobile-20-px pl-mobile-20-px'
                  ])}
                >
                  {t(
                    'To prawdziwe, świeże mięso i warzywa od lokalnych dostawców, gotowane w domowy sposób w niskich temperaturach, by zachować jak najwięcej składników odżywczych.'
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="overflow-hidden">
          <Row>
            <div className="col-sm-12">
              <div className={styles.bowlBanner}>
                <img
                  src="/images/photos/skladniki-nowe.png"
                  srcSet="/images/photos/skladniki-nowe.png 2x, /images/photos/skladniki-nowe.png 1x"
                  className={classNames(['my-2', 'img-fluid d-none d-lg-block'])}
                  alt="składniki naturalnej karmy dla psa"
                  style={{ minWidth: '100%' }}
                />

                <img
                  src="/images/photos/skladniki-nowe.png"
                  className={classNames(['my-2 d-lg-none w-100'])}
                  alt="składniki psiego jedzenia piesotto"
                  style={{ minWidth: '100%' }}
                />
              </div>

              {/* //temporary hidden */}
              <img
                src="/svgs/human_grade.svg"
                width="140"
                className={styles.humanGrade}
                alt="human grade"
                style={{ display: 'none' }}
              />
            </div>
          </Row>
        </Container>

        <Container className="pb-110-px pb-mobile-60-px overflow-hidden">
          <Col>
            <div className="text-center">
              <h2 className="text-green h2 my-3">
                {t('Dlaczego "psi rodzice" wybierają Piesotto')}
              </h2>
            </div>
          </Col>

          <Row className="text-purple">
            <Col xs="12" lg="3" className="mt-4">
              <Row>
                <Col xs="12">
                  <div className="text-center">
                    <img
                      style={{ maxHeight: '100px' }}
                      src="/images/photos/wysokasmakowitosc.png"
                      className="mw-100 mb-10-px"
                      alt="zbilansowana dieta piesotto"
                    />
                  </div>
                </Col>

                <Col
                  xs="12"
                  className="d-flex flex-column justify-content-start align-items-lg-center align-items-left font-weight-bold text-center mt-2"
                >
                  <div className="font-size-20 font-weight-700 font-size-mobile-18">
                    {t('Wysoka smakowitość')}
                  </div>

                  <div
                    className="font-size-14 font-weight-500 font-size-mobile-14"
                    style={{ lineHeight: '20px' }}
                  >
                    {t(
                      'Do wyboru aż 5 wysoko mięsnych przepisów, którym nie oprze się żaden niejadek'
                    )}
                  </div>
                </Col>
              </Row>
            </Col>

            <Col xs="12" lg="3" className="mt-4">
              <Row>
                <Col xs="12">
                  <div className="text-center">
                    <img
                      style={{ maxHeight: '100px' }}
                      src="/images/photos/zdrowawaga.png"
                      className="mw-100 mb-10-px"
                      alt="Zdrowa waga"
                    />
                  </div>
                </Col>

                <Col
                  xs="12"
                  className="d-flex flex-column justify-content-start align-items-lg-center align-items-left font-weight-bold text-center mt-2"
                >
                  <div className="font-size-20 font-weight-700 font-size-mobile-18">
                    {t('Zdrowa waga')}
                  </div>

                  <div
                    className="font-size-14 font-weight-500 font-size-mobile-14"
                    style={{ lineHeight: '20px' }}
                  >
                    {t('Wielkość porcji dopasowana do indywidualnych potrzeb psa')}
                  </div>
                </Col>
              </Row>
            </Col>

            <Col xs="12" lg="3" className="mt-4">
              <Row>
                <Col xs="12">
                  <div className="text-center">
                    <img
                      style={{ maxHeight: '100px' }}
                      src="/images/photos/twardedowody.png"
                      className="mw-100 mb-10-px"
                      alt="#Twarde dowody"
                    />
                  </div>
                </Col>

                <Col
                  xs="12"
                  className="d-flex flex-column justify-content-start align-items-lg-center align-items-left font-weight-bold text-center mt-2"
                >
                  <div className="font-size-20 font-weight-700 font-size-mobile-18">
                    {t('#Twarde dowody')}
                  </div>

                  <div
                    className="font-size-14 font-weight-500 font-size-mobile-14"
                    style={{ lineHeight: '20px' }}
                  >
                    {t(
                      'Efekty zauważalne już po pierwszych dniach! Zdrowsza i mniejsza #2, brak bąków, mocna i lśniąca sierść. To tak na początek...'
                    )}
                  </div>
                </Col>
              </Row>
            </Col>

            <Col xs="12" lg="3" className="mt-4">
              <Row>
                <Col xs="12">
                  <div className="text-center">
                    <img
                      style={{ maxHeight: '100px' }}
                      src="/images/photos/dlugiezycie.png"
                      className="mw-100 mb-10-px"
                      alt="pod okiem eksperta"
                    />
                  </div>
                </Col>

                <Col
                  xs="12"
                  className="d-flex flex-column justify-content-start align-items-lg-center align-items-left font-weight-bold text-center mt-2"
                >
                  <div className="font-size-20 font-weight-700 font-size-mobile-18">
                    {t('Długie, zdrowe życie')}
                  </div>

                  <div
                    className="font-size-14 font-weight-500 font-size-mobile-14"
                    style={{ lineHeight: '20px' }}
                  >
                    {t(
                      'Świeża dieta to mniej wizyt u weterynarza i dłuższe życie psa nawet o'
                    )}
                    {' '}
                    <span className="font-weight-bold">kilka lat!</span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        <div className="bg-dotted pt-120-px pt-mobile-70-px pb-90-px overflow-hidden">
          <Container>
            <Row>
              <Col className="">
                <div className="text-green text-center">
                  <h2>{t('Dla każdego coś zdrowego')}</h2>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md={{ offset: 1, size: 10 }}>
                <div className="text-purple2 text-center mb-50-px mb-mobile-5-px">
                  <div className={classNames([styles.ourMenuSubtitle, 'px-3'])}>
                    {t(
                      'Każdy pies, nawet największy niejadek, sportowiec czy ten z delikatnym brzuszkiem, znajdzie u nas coś dla siebie. Wszystko zdrowe, smaczne i naturalnie suplementowane.'
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mb-md-5 mt-5">
              <Col className="pb-5 px-0">
                <RecipeCarousel />
              </Col>
            </Row>
          </Container>
        </div>

        <HowItWorksSection isLoggedIn={isLoggedIn} />

        <Container className="overflow-hidden mb-5">
          <Row>
            <Col className="text-brown pt-120-px pt-mobile-70-px mx-auto" xs={12} lg={10}>
              <h2 className="text-center mb-4 text-green">{t('Mówią o nas')}</h2>
              <OpinionsCarousel />
            </Col>
          </Row>
        </Container>

        <SliderCalculator isLoggedIn={isLoggedIn} previousSectionColor="#fff" />

        <ShortFaq />

        <HappyStories withCta={!isLoggedIn} withModal={false} />

        <Footer
          hasNewsletter
          posterName={PosterNamesEnum.Home}
          posterImgAlt="naturalne jedzenie dla psa"
        />

        <CookieBar allowCookie={Boolean(allowCookie)} />
      </div>
    </>
  )
}

export default Home
