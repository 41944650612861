import classNames from 'classnames'
import React, { useState } from 'react'
import { Button, Card, CardBody, Collapse } from 'reactstrap'

import { translate } from '../../../common/translatable/translatable'

import { MyCollapseProps } from './@types/my-collapse.types'
import styles from './my-collapse.module.scss'

const MyCollapse: React.FC<MyCollapseProps> = ({ title, content }) => {
  const t = translate
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <div className={classNames(['my-3', styles.wrapper, 'shadow'])}>
      <Button
        block
        onClick={toggle}
        color="white"
        id="toggler"
        className={classNames([
          'text-left font-weight-bold py-3 d-flex justify-content-between align-items-center',
          styles.collapseToggler,
          !isOpen ? styles.collapseToggler__closed : ''
        ])}
      >
        <h3 className="font-size-20 font-weight-bold mb-0">{t(title)}</h3>
        <img
          src="/svgs/next.svg"
          width="15"
          className={classNames([
            isOpen ? styles.arrowActive : styles.arrow,
            'd-none d-md-inline-block'
          ])}
          alt="rozwiń"
        />
      </Button>
      <Collapse isOpen={isOpen}>
        <Card className={styles.collapseBody}>
          <CardBody className="pt-0">{t(content)}</CardBody>
        </Card>
      </Collapse>
      <noscript>
        {t(content)}
      </noscript>
    </div>
  )
}

export default MyCollapse
