import { useStateMachine } from 'little-state-machine';

import { setDiscountFromUrlAction } from './actions';

export const useDiscountFromUrl = () => {
  const { state, actions } = useStateMachine({
    setDiscountFromUrlAction,
  });

  const setDiscountFromUrl = (query) => {
    actions.setDiscountFromUrlAction(query);
  };

  return {
    setDiscountFromUrl,
    discountFromUrl: state.discountFromUrl,
  };
};
