import { GlobalState } from 'little-state-machine'
import { PlanStorageState } from 'src/client/common/@types/plan-storage-state.types'

export const updatePlanStorageAction = (
  globalState: GlobalState,
  payload: { planStorage: PlanStorageState }
) => {
  const { planStorage } = payload

  if (!planStorage) {
    return {
      ...globalState
    }
  }

  return {
    ...globalState,
    planStorage
  }
}
