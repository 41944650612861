import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'

import { PlanModalComponent } from '../../common/plan-modal/plan-modal.component'
import { translate } from '../../common/translatable/translatable'
import { usePlanModal } from '../../hooks/usePlanModal'
import DogsCarousel from '../static-pages/dogs-carousel/dogs-carousel.component'

type Props = {
  withCta?: boolean
  withModal?: boolean
  biggerBottom?: boolean
}

export const HappyStories: React.FC<Props> = ({ withCta, biggerBottom, withModal = true }) => {
  const t = translate

  const { toggleIsPlanModalOpen } = usePlanModal()

  const dogs = [
    {
      imageUrl: '/images/photos/ig-7.jpg',
      imageAlt: 'pies rambo z przesyłką piesotto',
      srcset: '/images/photos/ig-7.jpg 1x, /images/photos/ig-7_x2.jpg 2x',
      nickname: 'szpic_blue',
      content: [
        'Dzisiaj odpowiadamy na Wasze pytania dot. Karmy @piesotto',
        '➡️ Dlaczego zmieniliśmy karmę na Piesotto? Ostatnio nie chciałam w ogóle jeść wiec zmieniliśmy na jedzenie gotowane, które smakuje mi jak żadne inne 🐶'
      ]
    },
    {
      imageUrl: '/images/photos/ig-5.jpg',
      imageAlt: 'psia sesja z przesyłką piesotto',
      srcset: '/images/photos/ig-5.jpg 1x, /images/photos/ig-5_x2.jpg 2x',
      nickname: 'merllov_bordercollie',
      content: [
        'Zobaczcie cóż za prezent dostała dziś Karmen z samego rana!😍 przez 14 dni karmen będzie zajadać @piesotto !😍 paczuszka pięknie spersonalizowana do psich łapek!😍 Otwieramy!😍'
      ]
    },
    {
      imageUrl: '/images/photos/ig-6.gif',
      imageAlt: 'pies z jedzeniem piesotto',
      srcset: '/images/photos/ig-6.gif 1x, /images/photos/ig-6.gif 2x',
      nickname: 'chartbeat.pl',
      content: [
        'Tak nasze charciki rzucają się na @piesotto 🙈 I to z rybą, a mam totalnie nierybne psy! 🐟 (niech Was nie zwiedzie torebeczka trzymana w ręku 🐂)'
      ]
    },
    {
      imageUrl: '/images/photos/ig-8.jpg',
      imageAlt: 'psi klient piesotto',
      srcset: '/images/photos/ig-8.jpg 1x, /images/photos/ig-8_x2.jpg 2x',
      nickname: 'frenchie_lunka',
      content: ['Nowy smak od @piesotto smakuje tak dobrze, że aż ślinka cieknie! 🐟💛']
    }
  ]

  return (
    <div className="bg-snack overflow-hidden">
      <Container className="text-center pt-120-px pt-mobile-50-px">
        <Row>
          <Col>
            <h2 className="text-brown mt-3 mb-2">
              <span style={{ color: '#24493E' }}>{t('Ugotowaliśmy już ponad ')}</span>
              <span style={{ color: '#EE8252' }}>{t('7 000 000 ')}</span>
              <span style={{ color: '#24493E' }}>{t('świeżych posiłków')}</span>
            </h2>
            <div
              className="text-purple font-weight-500 pb-60-px"
              style={{ fontSize: '16px', lineHeight: '22px' }}
            >
              {t(
                'Nic nie przynosi nam tyle radości, co gotowanie dla Waszych piesków i oglądanie, jak ogromną radość i ekscytację wzbudza każda paczka Piesotto'
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="px-0">
            <DogsCarousel dogs={dogs} />
          </Col>
        </Row>
        {withCta && (
          <>
            {withModal && <PlanModalComponent />}
            <Row className="mt-60-px mb-100-px mb-mobile-60-px mt-mobile-40-px">
              <Col className="mx-auto">
                <Button
                  color="primary"
                  className="rounded-pill btn-wide"
                  onClick={toggleIsPlanModalOpen}
                >
                  <span className="ctaText">{t('Złóż zamówienie')}</span>
                </Button>
              </Col>
            </Row>
          </>
        )}
        {biggerBottom && <div style={{ height: '100px' }} />}
      </Container>
    </div>
  )
}
