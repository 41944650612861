import dynamic from 'next/dynamic'
import React from 'react'
import { CarouselItem } from 'reactstrap'

import { BrandItemProps } from '../brand-item/@types/brand-item.types'

const BrandItem = dynamic(() => import('../brand-item/brand-item.component'))
const CarouselWrapper = dynamic(() => import('../carousel-wrapper/carousel-wrapper.component'))

const BrandsCarousel: React.FC = () => {
  const items: BrandItemProps[] = [
    {
      img: '/svgs/kobieta.pl.svg',
      alt: 'domena kobieta.pl',
      content: 'Genialny pomysł polskiej firmy na dietę pudełkową dla zwierzaków',
      imgAlt: 'kobieta.pl'
    },
    {
      img: '/svgs/pns.svg',
      alt: 'pnś',
      content: 'Zaspokoi potrzeby kulinarne czworonożnych członków rodziny',
      imgAlt: 'Pytanie na śniadanie'
    },
    {
      img: '/svgs/google_logo.svg',
      alt: 'ocena google',
      isGoogle: true,
      content: '/svgs/five_stars.svg'
    },
    {
      img: '/svgs/zwierzecy-punkt-widzenia.svg',
      alt: 'zwierzęcy punkt widzenia',
      content: 'Idealne rozwiązanie dla psów borykających się z alergiami pokarmowymi',
      imgAlt: 'Zwierzęcy punkt widzenia'
    },
    {
      img: '/svgs/vouge_logo.svg',
      alt: 'vouge',
      extraClasses: 'mh-40',
      content:
        'Opiekun, który karmi psa jedzeniem jakości human grade zasługuje na miano świadomego',
      imgAlt: 'Vogue'
    }
  ]

  const slides = items.map(brand => (
    <CarouselItem key={brand.img} className="text-center px-5">
      <BrandItem {...brand} />
    </CarouselItem>
  ))

  return (
    <>
      <div className="d-lg-none">
        <CarouselWrapper slides={slides} noIndicators autoplay noArrows />
      </div>
      <div className="d-none d-lg-flex">
        {items.map(brand => (
          <div style={{ flex: 1 }} key={brand.img} className="mx-3">
            <BrandItem {...brand} />
          </div>
        ))}
      </div>
    </>
  )
}

export default BrandsCarousel
