export enum FbPixelEventName {
  PIXEL_USER_NAME = 'Krok 1 - Imię właściciela',
  PIXEL_USER_PRIORITIES = 'Krok 1a - Priorytety',
  PIXEL_DOG_NAME = 'Krok 2 - imię psa',
  PIXEL_DOG_BREED = 'Krok 3 - Rasa',
  PIXEL_DOG_AGE = 'Krok 4 - Wiek',
  PIXEL_DOG_WEIGHT_ADULT = 'Krok 4a - Waga dorosłego psa',
  PIXEL_DOG_WEIGHT = 'Krok 5 - Waga',
  PIXEL_DOG_SEX = 'Krok 6 - Płeć',
  PIXEL_DOG_STERILIZATION = 'Krok 7 - Sterylizacja',
  PIXEL_DOG_FOOD_PREFERENCES = 'Krok 8 - Preferencje żywieniowe',
  PIXEL_DOG_ACTIVITY = 'Krok 9 - Aktywność',
  PIXEL_DOG_FIGURE = 'Krok 10 - Figura',
  PIXEL_DOG_APPETITE = 'Krok 10a - Apetyt',
  PIXEL_DOG_ALLERGIES = 'Krok 11 - Alergie',
  PIXEL_DOG_HEALTH = 'Krok 12 - Problemy zdrowotne',
  PIXEL_SUMMARY = 'Krok 13 - Podsumowanie',
  PIXEL_FLAVORS = 'Krok 14 - Smaki',
  PIXEL_SUCCESS_PAGE = 'Purchase'
}

export type FbPixelEvent = {
  name: FbPixelEventName
  data: object
  eventID: string
}
